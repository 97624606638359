import React, { useEffect, useState } from "react";
import { avatars, signUpArrow } from "../../Images";
import { NiftyModal } from "../../../stories/NiftyModal";

export default function SignupModal({ setShowSignupModal, setShowLoginModal, redirectTo }) {
  const [email, setEmail] = useState("");
  const [csrf, setCsrf] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState(null);
  // Initialize the CSRF token
  useEffect(() => {
    setCsrf(document.querySelector('meta[name="csrf-token"]').content);
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (submitting) {
      return; // Prevent multiple form submissions
    }
    setSubmitting(true);
    fetch("/form_submissions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({ form_submission: { email, confirm_redirect: redirectTo } }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.form_submission.success) {
          setSubmitted(true);
        } else {
          setErrors(data.form_submission.errors);
        }
        setSubmitting(false);
      }).catch((error) => {
        setErrors(["An error occurred. Please try again."]);
        setSubmitting(false);
      });
    setEmail("");
  };

  const form = () => {
    if (submitted) {
      return (
        <div className="flex flex-col mx-auto lg:flex-row md:mx-0 md:pl-8">
          <div className="text-nifty-red text-footer-link font-roboto-mono ml-[40px] mt-[10px]">
            An email has been sent to you with your login url
          </div>
        </div>
      );
    } else {
      return (
        <form onSubmit={handleFormSubmit}>
          <div className="flex flex-col justify-between mx-auto my-8 sm:flex-row">
            <input
              placeholder="Your email address"
              className="px-8 sm:mr-8 grow modal-input-nifty-sign-up font-roboto-mono text-nifty-black focus:outline-none"
              type="text"
              value={email}
              onChange={handleEmailChange}
            />
            <div className="flex flex-1 w-full">
              <button
                disabled={email === "" || submitting}
                type="submit"
                className="mt-4 sm:mt-0 modal-btn-nifty bg-nifty-yellow font-roboto-mono-medium focus:outline-none"
              >
                Sign up
              </button>
            </div>
          </div>
          {errors && (
            <div className="text-nifty-red text-footer-link font-roboto-mono mt-[10px] mb-[10px]">
              {errors}
            </div>
          )}
        </form>
      );
    }
  };

  return (
    <NiftyModal
      showModal={true}
      title="Join thousands of collectors getting free NFTs"
      id="signup-modal"
      modalVisibilityChanged={(visible) => setShowSignupModal(visible)}
    >
      {form()}
      <p className="px-2 text-left text-nifty-dark-gray font-inter text-content-snug">
        Already have an account?{" "}
        <span
          onClick={() => setShowLoginModal(true)}
          className="text-sm cursor-pointer text-nifty-blue hover:underline"
        >
          Log In
        </span>
      </p>
      <div className="flex flex-col items-start mt-8 sm:items-center sm:flex-row">
        <img
          src={avatars}
          className="h-12 mb-2 text-left sm:mmb-0 sm:h-full"
        />
        <div className="flex w-full ml-2 sm:ml-4">
          <p className="text-left text-nifty-dark-gray font-inter text-content-snug">
            Join <span className="font-semibold">thousands</span> of other
            subscribers
          </p>
          <img
            src={signUpArrow}
            className="ml-4 -mt-1 transform rotate-180 -scale-x-100"
          />
        </div>
      </div>
    </NiftyModal>
  );
}
