import React, { useEffect, useState } from "react";
import LoginModal from "./LoginModal";
import SignupModal from "./SignupModal";

export default function Modals() {
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [redirect, setRedirect] = useState(null);

  const setModalState = (modal, redirectTo) => {
    if(redirectTo) {
      setRedirect(redirectTo);
    } else {
      setRedirect(null);
    }
    if (modal === "signup") {
      setShowSignupModal(true);
    } else {
      setShowLoginModal(true);
    }
  };

  useEffect(() => {
    if (showLoginModal) {
      setShowSignupModal(false);
    }
  }, [showLoginModal]);

  useEffect(() => {
    if (showSignupModal) {
      setShowLoginModal(false);
    }
  }, [showSignupModal]);

  const outsideClick = (e) => {
    const modalBg = document.getElementById("modal-bg");
    if (e.target == modalBg) {
      setShowSignupModal(false);
      setShowLoginModal(false);
    }
  };

  useEffect(() => {
    // need to set timeout to have react comps render
    setTimeout(() => {
      const signupModalBtns = document.getElementsByClassName("signup-modal-btn");
      const loginModalBtns = document.getElementsByClassName("login-modal-btn");
      Array.from(signupModalBtns).forEach((element) => {
        const redirectTo = element.dataset.redirectTo;
        element.addEventListener("click", () => { setModalState("signup", redirectTo) });
      });
      Array.from(loginModalBtns).forEach((element) => {
        const redirectTo = element.dataset.redirectTo;
        element.addEventListener("click", () => setModalState("login", redirectTo));
      });
      window.addEventListener("click", outsideClick);
    }, [100]);
  }, []);

  return (
    <>
      {showSignupModal && (
        <SignupModal
          setShowSignupModal={setShowSignupModal}
          setShowLoginModal={setShowLoginModal}
          redirectTo={redirect}
        />
      )}
      {showLoginModal && (
        <LoginModal
          setShowSignupModal={setShowSignupModal}
          setShowLoginModal={setShowLoginModal}
          redirectTo={redirect}
        />
      )}
    </>
  );
}
