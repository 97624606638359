import React, { useState, useEffect } from "react";
import { close } from "../../Images";
import { login, emailLogin } from '../../../utils/api';
import { NiftyModal } from "../../../stories/NiftyModal";

export default function LoginModal({ setShowLoginModal, setShowSignupModal, redirectTo }) {
  const [email, setEmail] = useState("");
  const [csrf, setCsrf] = useState("");
  const [usePassword, setUsePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState(null);
  // Initialize the CSRF token
  useEffect(() => {
    setCsrf(document.querySelector('meta[name="csrf-token"]').content);
  }, []);

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (submitting) {
      return; // Prevent multiple form submissions
    }
    setSubmitting(true);
    var res;
    if(usePassword) {
      res = await login(email, password);
      if (res.success) {
        console.log('LOGIN SUCCEEDED');
        window.location = '/';
        return;
      }
    } else {
      res = await emailLogin(email, redirectTo);
    } 
    if (res.success) {
      setSubmitted(true);
      setEmail("");
    } else if (res.errors) {
      setErrors(res.errors);
    } else {
      setErrors(["An error occurred. Please try again."]);
    }
    setSubmitting(false);
  };

  const form = () => {
    if (submitted) {
      return (
        <div className="flex flex-col mx-auto lg:flex-row md:mx-0 md:pl-8">
          <div className="text-nifty-red text-footer-link font-roboto-mono ml-[40px] mt-[10px] mb-[10px]">
            An email has been sent to you with your login url
          </div>
        </div>
      );
    } else {
      return (
        <form onSubmit={handleFormSubmit}>
          <div className="flex flex-col justify-between mx-auto my-8">
            <input
              placeholder="Your email address"
              className="px-8 grow modal-input-nifty-sign-up font-roboto-mono text-nifty-black focus:outline-none"
              type="text"
              value={email}
              onChange={handleEmailChange}
            />
            {usePassword && (
              <input
                placeholder="Password"
                className="px-8 mt-4 grow modal-input-nifty-sign-up font-roboto-mono text-nifty-black focus:outline-none"
                type="password"
                value={password}
                onChange={handlePasswordChange}
              />
            )}
            <button
              type="submit"
              className="w-full mt-4 login-modal-btn-nifty bg-nifty-yellow font-roboto-mono-medium focus:outline-none"
              disabled={submitting}
            >
              Log in
            </button>
          </div>
          {errors && (
            <div className="text-nifty-red text-footer-link font-roboto-mono mt-[10px] mb-[10px]">
              {errors}
            </div>
          )}
        </form>
      );
    }
  };

  return (
    <NiftyModal
      showModal={true}
      title="Log in to your account."
      modalVisibilityChanged={(visible) => setShowLoginModal(visible)}
    >
      {form()}
      <div className="px-2 text-left text-nifty-dark-gray font-inter text-content-snug flex flex-col md:flex-row">
        <div>
          <span>Don't have an account?</span>
          <span
            onClick={() => setShowSignupModal(true)}
            className="text-sm cursor-pointer text-nifty-blue hover:underline ml-2"
          >
            Sign up
          </span>
        </div>
        <div className={submitted ? 'hidden' : "md:ml-2"}>
          <span className="md:ml-2">{usePassword ? 'No password?' : 'Have a password?'}</span>
          <span
            onClick={() => setUsePassword(!usePassword)}
            className="text-sm cursor-pointer text-nifty-blue hover:underline ml-2"
          >{usePassword ? 'Login without one' : 'Use it'}</span>
        </div>
      </div>
    </NiftyModal>
  );
}
